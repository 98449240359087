
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        






































































































































































































































.cta,
[class*='cta--'] {
  @extend %text-center;
  @include bg-color;

  position: relative;
  padding: $spacing * 2;

  @include mq('m') {
    padding: $spacing * 2.5;
  }

  @include mq('xl') {
    padding: $spacing * 4;
  }
}

.cta__title,
.cta__excerpt {
  @include dark-bgs {
    color: $white;
  }
}

.cta__title {
  margin: 0;
}

.cta__excerpt {
  @extend %fw-light;

  max-width: 56rem;
  margin: 1em auto 0;
  font-family: $ff-default;
  line-height: 1.65;

  @include mq(m) {
    line-height: 1.5;
  }
}

.cta__btn {
  position: relative;
  z-index: 100;
  display: inline-flex;
  align-items: center;
  margin: 1.5em 0;

  .icon {
    margin-right: $spacing * 0.5;
  }

  .shared-icons-calendar {
    width: 2.4rem;
    height: 2.4rem;
    fill: $c-pink-medium;
  }

  & + & {
    @include mq(s) {
      margin-left: $spacing;
    }

    @include mq(l) {
      margin-left: $spacing * 2;
    }
  }
}

.cta__speedlines {
  position: absolute;
  z-index: layer(high);
  top: 0;
  left: 50%;
  width: 100%;
  max-width: 42rem;
  height: 110%;
  transform: translateX(-50%);

  .speedlines__item,
  [class*='speedlines__item--'] {
    @include mq($until: m) {
      opacity: 0;
    }
  }
}

.cta-picture {
  max-width: 25rem;
  margin: 0 auto $spacing;
}

.cta__afterlink {
  @include dark-bgs {
    color: $white;

    ::v-deep {
      a {
        color: $white;
      }
    }
  }
}

.cta__promo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $spacing;
}

.cta__promo__label {
  @extend %fw-bold;
  @extend %text-uppercase;

  display: inline-flex;
  margin-right: $spacing;
  padding: $spacing / 4 $spacing / 2;
  color: $white;
  font-family: $ff-alt;
  font-size: 1.3rem;
  letter-spacing: 1px;
  background-color: $c-orange;
  border-radius: 0.2rem;
}

.cta__promo__text {
  @extend %fw-bold;

  font-family: $ff-alt;
  font-size: 1.3rem;
}

.cta__cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: $spacing * 5;
}

.cta__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: $spacing * 2;
  font-weight: 400;
  text-align: left;
  background: $white;
  border-radius: 8px;
  filter: drop-shadow(0 1rem 4rem rgba($black, 0.1));

  .cta__btn,
  .cta__link {
    margin-top: $spacing * 2;
    margin-bottom: 0;
  }

  &:first-child {
    margin-bottom: 5rem;
    color: $white;
    background: $c-blue-dark;
  }

  @include mq(m) {
    width: 80%;
    margin: 0 auto;
  }

  @include mq(l) {
    width: 46%;

    &:first-child {
      margin-bottom: 0;
    }
  }
}
