
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-business';
        












































































































































.net-homepass,
[class*='net-homepass--'] {
  overflow: hidden;
}

.net-homepass__hero {
  ::v-deep {
    .hero__slides__item__icon {
      margin-bottom: $spacing * 2;

      .icon {
        display: block;
        max-height: 4.7rem;
      }
    }

    .hero__slides__item__title {
      img {
        max-width: 30rem;
      }
    }

    .hero__slides__item__subtitle,
    .hero__slides__item__suptitle {
      max-width: 60rem;
      margin-top: $spacing;
      margin-bottom: $spacing * 3;
    }
  }
}

.net-homepass__hero__content__anchors {
  display: flex;
  flex-direction: column;
}

.net-homepass__hero__content__anchor {
  @extend %fw-bold;

  display: inline-flex;
  align-items: center;
  color: $c-blue-dark;
  line-height: 1;
  text-decoration: none;

  &:first-child {
    margin-right: $spacing * 1.5;
  }

  .link--revert {
    color: $white;
    background-image: linear-gradient(white, white),
      linear-gradient($c-pink-medium, $c-pink-medium);

    &::after {
      background-color: $white;
    }
  }

  .icon {
    width: 3rem;
    height: 4rem;
    margin-right: $spacing;
    fill: $white;
  }
}

.net-homepass__pods {
  background-color: $c-light-gray;

  ::v-deep {
    .section-row__picture {
      position: relative;
      overflow: hidden;

      img {
        max-height: none;
        margin: 0;
      }

      .net-homepass__pods__play img {
        width: 10rem;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: (9 / 16) * 100%;
      }
    }

    .section-row__speedlines {
      height: 150%;
    }
  }

  @include mq($until: l) {
    padding-bottom: 0 !important;

    ::v-deep {
      .section-row__inner {
        display: flex;
        flex-direction: column-reverse;
      }

      .section-row__picture {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  @include mq(l) {
    padding-bottom: 0 !important;

    ::v-deep {
      .speedlines {
        width: 100%;
      }

      .section-row__content {
        padding-top: $spacing * 6;
        padding-bottom: $spacing * 6;

        @include mq(l) {
          width: col(5);
        }
      }

      .section-row__picture {
        @include mq(l) {
          width: col(8);
          margin-left: col(-2);
        }
      }
    }
  }
}

.net-homepass__sense {
  background-color: $c-light-gray;

  ::v-deep {
    .section-row__inner {
      @include mq(l) {
        flex-direction: row-reverse;
      }
    }

    .section-row__picture {
      position: relative;
      overflow: hidden;

      img {
        max-height: none;
        margin: 0;
      }

      .net-homepass__pods__play img {
        width: 10rem;
      }

      &::before {
        content: '';
        display: block;
        width: 100%;
        padding-top: (9 / 16) * 100%;
      }

      @include mq(l) {
        width: col(8);
        margin-right: col(-2);
      }
    }

    .section-row__speedlines {
      height: 150%;
    }

    .section-row__content {
      @include mq(l) {
        width: col(6);
        margin-right: col(1);
        margin-left: 0;
      }
    }
  }

  @include mq($until: l) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    ::v-deep {
      .section-row__inner {
        display: flex;
        flex-direction: column-reverse;
      }

      .section-row__picture {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  @include mq(l) {
    padding-bottom: 0 !important;

    ::v-deep {
      .speedlines {
        width: 100%;
      }

      .section-row__content {
        // padding-top: $spacing * 6;
        padding-bottom: $spacing * 6;
      }
    }
  }
}

.net-homepass__evo {
  padding-bottom: 0 !important;

  @include mq($until: l) {
    margin-bottom: $spacing * 2;

    ::v-deep {
      .section-row__inner {
        display: flex;
        flex-direction: column-reverse !important;
      }

      .section-row__picture {
        margin-bottom: 0;
      }
    }
  }
}

.net-homepass__pods__play {
  @include get-all-space;

  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    transition: transform 0.3s $ease;
  }

  &:hover {
    cursor: pointer;

    img {
      transform: scale(1.05);
    }
  }
}

.net-homepass__evo {
  ::v-deep {
    .section-row__picture {
      figure {
        position: relative;
        z-index: 10;
        margin: 0 auto;

        @include mq($until: l) {
          img {
            max-height: 40rem;
          }
        }

        @include mq(l) {
          img {
            width: auto;
            max-height: 50rem;
          }
        }

        @include mq(xxl) {
          img {
            max-height: 70rem;
          }
        }
      }

      video {
        display: block;
        height: 100%;
        max-height: none;
        margin: 0 auto;
        border-radius: 22px;
      }
    }
  }

  @include mq(l) {
    ::v-deep .section-row__content {
      padding-bottom: $spacing * 4;
    }
  }
}

.net-homepass__evo__plume {
  position: relative;
  margin: $spacing * 2 auto #{-$spacing};

  @include mq(l, xxl) {
    margin: 0 auto #{-$spacing * 6};
  }

  @include mq(xxl) {
    margin: 0 auto #{-$spacing * 4};
  }
}

.net-homepass__evo__plume__video {
  @include center-x;

  z-index: 2;
  top: 5px;
  height: 95%;
}

.net-homepass__evo__apps {
  strong {
    display: block;
    margin-bottom: $spacing * 0.5;
  }

  a {
    margin-right: $spacing * 0.5;
  }

  img {
    max-height: 5rem;
  }
}

.net-homepass__services {
  background-color: transparent !important;

  ::v-deep {
    .card-small__inner {
      padding-top: $spacing * 4.5 !important;
    }

    .card-small-item__text {
      margin-bottom: $spacing * 2;
    }

    .card-small-item__link {
      width: calc(100% + #{$spacing * 3}); //IE Fix
      min-height: 1px; // IE Fix
      margin-right: -#{$spacing * 2};
      margin-bottom: -#{$spacing * 1.5};
      margin-left: -#{$spacing * 2};

      img {
        display: block;
        width: 100%;
      }
    }
  }

  @include mq(l, xl) {
    ::v-deep .card-small-item__link {
      margin-right: -#{$spacing};
      margin-bottom: -#{$spacing * 2};
      margin-left: -#{$spacing};
    }
  }

  @include mq(xl) {
    ::v-deep {
      .card-small-item__link {
        width: calc(100% + #{$spacing * 4}); //IE Fix
        margin-right: -#{$spacing * 2};
        margin-bottom: -#{$spacing * 3};
        margin-left: -#{$spacing * 2};
      }

      .card-small__inner {
        padding-top: $spacing * 7 !important;
      }
    }
  }
}

.net-homepass__services-list {
  background: $c-white-dark;
}

.net-homepass .services-list__list__item__icon {
  fill: none;
  stroke: $c-pink-medium;
}

.net-homepass__cta {
  ::v-deep {
    .cta__speedlines {
      display: none;
    }

    .cta__cards {
      display: none;
    }

    .cta__title {
      margin: $spacing * 2 0;
    }

    .cta__excerpt {
      margin-bottom: $spacing * 2;
      font-size: 2rem;

      .title {
        font-family: $ff-alt;
        font-size: 1.8rem;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 1px;
      }

      .offer {
        color: $c-orange;
        font-weight: bold;
      }

      .price {
        margin-top: 1rem;
        display: block;
        color: $c-pink-medium;
        font-size: 2.6rem;
        font-weight: bold;
      }
    }
  }
}
