/// Mixin to customize scrollbars
/// Beware, this does not work in all browsers
/// @author Hugo Giraudel
/// @param {Length} $size - Horizontal height and vertical width
/// @param {Color} $foreground-color - color
/// @param {Color} $background-color [mix($foreground-color, white, 50%)] - color
/// @example scss - Scrollbar styling
///   @include scrollbars(.5em, slategray);
@mixin scrollbars(
  $size: 6px,
  $foreground-color: $black,
  $background-color: $white
) {
  // Standard version (Firefox only for now)
  scrollbar-color: $foreground-color $background-color;

  // For Chrome & Safari
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }
}

// Scalable Text:
// This mixin allows smooth text scaling in responsive design.
// See the `$scalable-text-map` in `_variables.scss` for more informations.
// @param {String} key - The key from the `$scalable-text-map`
//
// @example scss
// h1 {
//  @include scalable-text('h1');
// }
@mixin scalable-text($key) {
  $map: map-get($scalable-text-map, $key);

  $min: map-get($map, 'min');
  $max: map-get($map, 'max');

  $font-min-size: nth($min, 1);
  $font-max-size: nth($max, 1);

  $font-min-break: valid-breakpoint(nth($min, 2));
  $font-max-break: valid-breakpoint(nth($max, 2));

  font-size: calc(
    #{$font-min-size} + (
        #{strip-unit($font-max-size)} - #{strip-unit($font-min-size)}
      ) * ((100vw - #{$font-min-break}) /
          (#{strip-unit($font-max-break)} - #{strip-unit($font-min-break)}))
  ); // sass-lint:disable-line max-line-length

  @include mq($until: $font-min-break) {
    font-size: $font-min-size;
  }
  @include mq($from: $font-max-break) {
    font-size: $font-max-size;
  }
}

@mixin center-x {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin center-y {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin center-xy {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin get-all-space {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin aspect-ratio($width, $height) {
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: ($height / $width) * 100%;
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@mixin image-fit($fit: cover, $position: null) {
  @include get-all-space;

  object-fit: $fit;

  @if $position {
    font-family: 'object-fit: #{$fit}; object-position: #{$position}';
    object-position: $position;
  } @else {
    font-family: 'object-fit: #{$fit}';
  }
}

@mixin owl($margin: false) {
  > :first-child {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }

  > :last-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-top: 0;

    @if $margin {
      margin-bottom: $margin;
    }
  }
}

@mixin bg-color {
  @each $class, $color in $bg-colors {
    &.#{$class} {
      background-color: $color;

      &::before,
      &::after {
        background-color: $color;
      }
    }
  }
}

@mixin dark-bgs {
  .#{$bg-dark-blue} &,
  .#{$bg-darker-blue} &,
  .#{$bg-orange} & {
    @content;
  }
}

@mixin fluid-type($from, $to, $until: xxl) {
  font-size: calc(
    #{$from}px + (#{$to} - #{$from}) *
      (
        (100vw - #{mq-get-breakpoint-width('xxs')}) /
          (
            #{strip-unit(mq-get-breakpoint-width($until))} - #{strip-unit(
                mq-get-breakpoint-width(xxs)
              )}
          )
      )
  );

  @include mq(xxl) {
    font-size: #{$to}px;
  }
}

@mixin clamp(
  $property,
  $min-size,
  $scaler,
  $max-size,
  $min-size-left: false,
  $scaler-left: false,
  $max-size-left: false
) {
  @if $min-size-left == false {
    #{$property}: $max-size;
    #{$property}: #{'min(max(#{$min-size}, #{$scaler}), #{$max-size})'};
    #{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size});
  } @else if $min-size-left == 0 or $min-size-left == auto {
    #{$property}: $max-size $min-size-left;
    #{$property}: #{'min(max(#{$min-size}, #{$scaler}), #{$max-size})'}
      $min-size-left;
    #{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size}) $min-size-left;
  } @else {
    #{$property}: $max-size $min-size-left;
    #{$property}: #{'min(max(#{$min-size}, #{$scaler}), #{$max-size})'}
      #{'min(max(#{$min-size-left}, #{$scaler-left}), #{$max-size-left})'};
    #{$property}: clamp(#{$min-size}, #{$scaler}, #{$max-size})
      clamp(#{$min-size-left}, #{$scaler-left}, #{$max-size-left});
  }
}

@mixin liquid($property, $min, $max) {
  $scaler: #{strip-unit($max) * 100 / strip-unit($wrapper-width) + 'vw'};
  @include clamp($property, $min, $scaler, $max);
}

// Max-width include
// @mixin max-width {
//   max-width: $wrapper-width;
//   margin: 0 auto;
// }
// @mixin max-width {
//   max-width: $wrapper-width;
//   margin: 0 $spacing;

//   @include mq('m') {
//     margin: 0 $spacing * 3.5;
//   }

//   @include mq('xl') {
//     margin: 0 $spacing * 6;
//   }

//   @include mq('xxl') {
//     margin: 0 auto;
//   }
// }

@mixin fluid($property, $map) {
  $finalmap: ();

  @each $breakpoint, $value in $map {
    $nameToPx: map-get($mq-breakpoints, $breakpoint);

    @if $nameToPx {
      $finalmap: map-merge(
        $finalmap,
        (
          $nameToPx: $value,
        )
      );
    } @else {
      $finalmap: map-merge(
        $finalmap,
        (
          $breakpoint: $value,
        )
      );
    }
  }

  @include poly-fluid-sizing($property, $finalmap);
}
